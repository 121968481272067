import React, { useState, useEffect, useRef } from 'react';

//services
import VideographyService from '../../services/videography'


//thumbnails

function Videography() {


    //content
    const ph_content1 = require('../../assets/img/videography/photo01.jpg');
    const ph_content2 = require('../../assets/img/videography/photo02.jpg');
    const ph_content3 = require('../../assets/img/videography/photo03.jpg');

    //server get
    //videos
    const [getVideos, setGetVideos] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);


    //height div video
    const videoContainerRef = useRef(null);
    const [divHeightVideo, setDivHeightVideo] = useState(0);
    const [selectedImageIndex, setSelectedImageIndex] = useState('');

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        onLoadVideography();
        window.addEventListener('resize', handleResize);

        if (videoContainerRef.current) {
            const height = videoContainerRef.current.offsetHeight;
            setDivHeightVideo(height);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [activeIndex, getVideos, isMobile]);
    const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
    };

    //get first load places
    const onLoadVideography = async () => {
        try {
            const getAllVideos = await VideographyService.getVideos();
            // console.log(getAllVideos.data.data);
            if (getAllVideos.status === 200 && getAllVideos.data.statusCode === 201) {
                setGetVideos(getAllVideos.data.data)
            } else if (getAllVideos.status === 200 && getAllVideos.data.statusCode === 404) {
            } else if (getAllVideos.status === 200 && getAllVideos.data.output.statusCode === 400) {
            } else {
            }
        } catch (error) {
        }
    }
    //detect carousel active
    const handleIndicatorClick = (index) => {
        setActiveIndex(index);
    };

    //stop video
    const handleVideoClick = (index) => {
        // Pausar el video actual antes de cambiar al siguiente
        const video = document.getElementById(`video-${activeIndex}`);
        if (video) {
            video.pause();
        }
        setActiveIndex(index);
    };

    const [videoOrientation, setVideoOrientation] = useState('');
    const handleLoadedMetadata = (event, index) => {
        const video = event.target;
        const isVertical = video.videoHeight > video.videoWidth;

        // Mantén un objeto que almacene la orientación de cada video
        setVideoOrientation(prevOrientation => ({
            ...prevOrientation,
            [index]: isVertical ? 'video-vertical' : 'video-horizontal'
        }));
    };
    return (
        <>
            <div className='banner-photography'>
                <div className='row'>
                    <div className="col-sm-12 col-md-3 col-lg-3 overflow-auto videography-thumbnail order-2 order-sm-2 order-md-2 order-md-2"
                        style={isMobile ? { height: 'auto' } : { height: `${divHeightVideo}px` }}
                    >
                        <div className='title d-none d-lg-block '>
                            <h2 className="sticky-title">All Videos</h2>
                        </div>
                        {Array.isArray(getVideos) ? (
                            getVideos.map((item, i) => (
                                <div key={i} className="thumbnail">
                                    <div className={`${selectedImageIndex === i ? '' : 'selected-image'}`}>
                                        {item.loading ? (
                                            <div className='center-spinner'>
                                                <div className='loader'></div>
                                            </div>
                                        ) : (
                                            <img
                                                type="button"
                                                data-bs-target="#VideoGraphyCarousel"
                                                data-bs-slide-to={i}
                                                src={item.vidThumbnailUrl}
                                                onClick={() => {
                                                    handleIndicatorClick(i);
                                                    setSelectedImageIndex(i);
                                                }}
                                            />
                                        )}
                                        <h6>{item.vidName}</h6>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='center-spinner'>
                                <div className='loader'></div>
                            </div>
                        )}
                    </div>

                    <div className='col-sm-12 col-md-12 col-lg-9 order-1 order-sm-1 order-md-1 order-lg-1 videography-video'>
                        <div className="ul-carousel-container">
                            <div id="VideoGraphyCarousel" className="carousel slide" >
                                <div className="carousel-inner" ref={videoContainerRef}>
                                    {Array.isArray(getVideos) ? (
                                        getVideos.map((item, i) => (
                                            <div key={i} className={`carousel-item video-center ${i === activeIndex ? "active" : ""} `}>
                                                <video
                                                    id={`video-${i}`}
                                                    playsInline
                                                    loop
                                                    autoPlay
                                                    muted
                                                    controls
                                                    className={` ${videoOrientation[i] || "carousel-video"}`}
                                                    onClick={() => handleVideoClick(i)}
                                                    onLoadedMetadata={(e) => handleLoadedMetadata(e, i)}
                                                >
                                                    <source src={item.vidUrl} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='center-spinner'>
                                            <div className='loader'></div>
                                        </div>
                                    )}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#VideoGraphyCarousel" data-bs-slide="prev"
                                    onClick={() => {
                                        setActiveIndex((prevIndex) => (prevIndex - 1 + getVideos.length) % getVideos.length);
                                        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + getVideos.length) % getVideos.length);
                                    }}
                                >
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#VideoGraphyCarousel" data-bs-slide="next"
                                    onClick={() => {
                                        setActiveIndex((prevIndex) => (prevIndex + 1) % getVideos.length);
                                        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % getVideos.length);
                                    }} >
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
            <div className="content-photography accordion" id="accordionExample">
                <div className="card">
                    <div className="card-header view-more" id="headingOne">
                        <div className="mb-0 more-container">
                            <button className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                View services
                                <br />
                                <i className="fa-solid fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>

                    <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="card-body">
                            <div className="bg-pattern-1 content-photography">
                                <div className="container-md">
                                    <div className="elemements-ph">
                                        <div className='elemements-ph '>
                                            <div className='row '>
                                                <div className='col-md-12 col-lg-6 img-r order-1 order-lg-1'>
                                                    <img src={ph_content1} className='img-fluid' />
                                                </div>
                                                <div className='col-md-12 col-lg-6 content-r order-2 order-lg-2'>
                                                    <h4>fashion products</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                                </div>
                                            </ div>
                                            <div className='row'>
                                                <div className='col-md-12 col-lg-6 order-1 order-lg-2'>
                                                    <img src={ph_content2} className='img-fluid' />
                                                </div>
                                                <div className='col-md-12 col-lg-6 content-l order-2 order-lg-1'>
                                                    <h4>comercial and media</h4>
                                                    <p className='justify-content-end'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-md-12 col-lg-6 order-1 img-r order-lg-1'>
                                                    <img src={ph_content3} className='img-fluid' />
                                                </div>
                                                <div className='col-md-12 col-lg-6 content-r order-2 order-lg-2'>
                                                    <h4>aerial</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                                </div>
                                            </ div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export { Videography }