import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class CategoryService {

    getCategories(data,Authorization) {
        return http.get(`/category/get_categories`, data,Authorization);
    }
    getGalleriesById(id) {
        return http.get(`/category/get_gallery/${id}`);
    }
    getimagesById(id) {
        return http.get(`/category/get_images/${id}`);
    }
}
export default new CategoryService();