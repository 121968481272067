import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

function Footer() {

    //images
    const logo = require('../../assets/img/logo/logo_white.png');
    const logoFooter = require('../../assets/img/footer/Logo-Maju.png');

    return (
        <>
            <footer className='bg-dark-footer footer bg-pattern-footer'>
                <div className='container'>
                    <div className='footer-container'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-sm-12 col-md-12 col-lg-4 colum order-lg-1 order-2 menuu mb-3'>
                                <ul className="list-unstyled">
                                    <li className='brown-text font-45'>about us</li>
                                    <li className='brown-text font-45'>contact us</li>
                                    <li className='brown-text font-45'>affiliates</li>
                                    <li className='brown-text font-45'>privacy policy</li>
                                    <li className='brown-text font-45'>terms and conditions</li>
                                    <li className='brown-text font-45'>FAQ's</li>
                                </ul>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4 colum text-center order-lg-2 order-1 dashed-border mb-3'>
                                <img src={logoFooter} alt="Logo" />
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4 colum order-lg-3 order-3 dashed-border'>
                                <div className='row suscribe '>
                                    <p className='brown-text  '>subscribe newsletter</p>
                                    <div className="custom-input-group col-md-12 ">
                                        <input type="text" className="form-control bg-ligth-gray " placeholder="Email" aria-label="Email" aria-describedby="button-addon2" />
                                        <button className="btn btn-primary">Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='copyrigth'>
                    <p className='d-flex justify-content-center copyrigth font-45 txt-bold'>Copyrigth © 2019 MAJU CURATED INC, and All Rights Reserved.</p>
                </div>
            </footer>

        </>
    )
}
export { Footer }