import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

import axios from 'axios';
function Home() {
    //images
    const slide1 = require('../../assets/img/home/slider_home_01.jpg');
    const slide2 = require('../../assets/img/home/slider_home_02.jpg');
    const slide3 = require('../../assets/img/home/slider_home_03.jpg');
    const slide4 = require('../../assets/img/home/slider_home_04.jpg');
    const slide5 = require('../../assets/img/home/slider_home_05.jpg');

    //arrows
    const arrowR = require('../../assets/img/design/arrow_right.png');
    const arrowL = require('../../assets/img/design/arrow_left.png');

    const photography = require('../../assets/img/home/fotografia.jpg');
    const videography = require('../../assets/img/home/video.jpg');
    const desing = require('../../assets/img/home/design.jpg');

    //icons
    const pinterest = require('../../assets/img/icons/icon01.png');
    const facebook = require('../../assets/img/icons/icon02.png');
    const instagram = require('../../assets/img/icons/icon03.png');
    const youtube = require('../../assets/img/icons/icon04.png');

    const [posts, setPosts] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);

        const accessToken = 'IGQVJYc3BvQ29KaHFJZAi1DbW4yYkF0NlZAGX3VHemFNb05aYVotaEltaV9aZAVh0QmZAMQVhoNUNtMExkMXFueDd4N0RZATHFIa2RCTDV0elptOXZAGbWp6eHpKS2tMYTIxYnZAEbzJmRHpCN1FNa3lyUGxlRQZDZD';
        const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${accessToken}&limit=4&order=desc`;
        axios.get(apiUrl)
            .then(response => {
                setPosts(response.data.data);
               // console.log(response.data.data)
            })
            .catch(error => {
                console.error('Error al obtener los posts de Instagram:');
            });
    }, []);
    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    }
    return (
        <>
            <div id="carouselHomeMaju" className="carousel slide home-slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselHomeMaju" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselHomeMaju" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselHomeMaju" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselHomeMaju" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselHomeMaju" data-bs-slide-to="4" aria-label="Slide 0"></button>
                </div>

                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="2000">
                        <img src={slide1} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src={slide2} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src={slide3} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src={slide4} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <img src={slide5} className="d-block w-100" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev " type="button" data-bs-target="#carouselHomeMaju" data-bs-slide="prev">
                    <img src={arrowL} />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselHomeMaju" data-bs-slide="next">
                    <img src={arrowR} />
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='img-info-home '>
                <div className='bg-img-home '>
                    <div className=''>
                        <div className='img-content'>
                            <div className='img-pd-l'>
                            </div>
                            <div className='img-m-l'>
                                <NavLink to={'/photography'}>
                                    <img className='img-fluid ' src={photography} />
                                    <p>photography</p>
                                </NavLink>
                            </div>
                            <div className=''>
                                <NavLink to={'/videography'}>
                                    <img className='img-fluid' src={videography} />
                                    <p>videography</p>
                                </NavLink>
                            </div>
                            <div className='img-m-r'>
                                <NavLink to={'/design'}>
                                    <img className='img-fluid ' src={desing} />
                                    <p>design</p>
                                </NavLink>
                            </div>
                            <div className='img-pd-r text-center'>
                                <p className='book ' >book now</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='home-social'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-12 justify-content-center'>
                            <div>
                                <p>follow us @majucurated</p>
                            </div>
                            <div className='flex '>
                                <a><img className='img-fluid' src={instagram} /></a>
                                <a><img className='img-fluid' src={facebook} /></a>
                                <a><img className='img-fluid' src={pinterest} /></a>
                                <a><img className='img-fluid' src={youtube} /></a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container instagram mb-5'>
                <div className='row'>
                    {posts.length > 0 ? (
                        posts.map((item, i) => (
                            <div className='col-sm-12 col-md-6 col-lg-3 card mb-3' key={i}>
                                <div className='content-box'>
                                    {item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM' ? (
                                        <img src={item.media_url} className="card-img-top" alt="..." />
                                    ) : item.media_type === 'VIDEO' ? (
                                        <video controls className="card-img-top">
                                            <source src={item.media_url} type="video/mp4" />
                                        </video>
                                    ) : null}
                                    <div className="card-body">
                                        <h5>Maju Curated</h5>
                                        <div className='card-content'>
                                            <p className="card-text ">{formatDate(item.timestamp)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='col-sm-12 col-md-6 col-lg-3 card mb-3'>
                            <div className='content-box placeholder-glow'>
                                <span className="placeholder col-12"></span>
                                <div className="card-body">
                                    <span className="placeholder col-3"></span>
                                    <div className='card-content'>
                                        <span className="placeholder col-3"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='container mb-5'>
                <div className='row'>
                    <div className='col-md-12 view-gallery'>
                        <button className='btn'>view gallery</button>
                    </div>
                </div>
            </div>



        </>
    )
}
export { Home }