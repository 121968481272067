import React, { useState, useEffect } from 'react';


function Design() {

    //images content
    const image1 = require('../../assets/img/design/header-design.jpg');
    const image2 = require('../../assets/img/design/branding.jpg');
    const image3 = require('../../assets/img/design/marketing.jpg');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>
            <div className='design'>
                <div className='container-design'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-5 box-title order-2 order-lg-1'>
                            <h3 className="main-title">Design</h3>
                            <h3 className="ghost-title">Design</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. sed do eiusmod tempor
                                incididunt ut labore et dolore mana aliqua.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                sed do eiusmod tempor incididunt ut labore et dolore mana aliqua.</p>
                            <button className='btn'>Books Us</button>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-7 box-img order-1 order-lg-2'>
                            <img src={image1} />
                        </div>
                    </div>
                </div>

                <div className='container-design '>
                    <div className='row black'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 box-img order-1 order-lg-1'>
                            <img src={image2} />
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 box-title order-2 order-lg-2'>
                            <h3 className="main-title">Branding</h3>
                            <h3 className="ghost-title">Branding</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. sed do eiusmod tempor
                                incididunt ut labore et dolore mana aliqua.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                sed do eiusmod tempor incididunt ut labore et dolore mana aliqua.</p>
                            <button className='btn'>Books Us</button>
                        </div>
                    </div>
                </div>

                <div className='container-design'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-5 box-title order-2 order-lg-1'>
                            <h3 className="main-title">Marketing Strategy</h3>
                            <h3 className="ghost-title">Marketing </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. sed do eiusmod tempor
                                incididunt ut labore et dolore mana aliqua.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                sed do eiusmod tempor incididunt ut labore et dolore mana aliqua.</p>
                            <button className='btn'>Books Us</button>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-7 box-img order-1 order-lg-2'>
                            <img src={image3} />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export { Design }