import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

//pages
import { Home } from '../components/home';
import { Photography } from '../components/photography';
import { Videography } from '../components/videography';
import { Design } from '../components/design';


function Menu() {

    //images
    const logo = require('../../assets/img/logo/logo_white.png');
    const logoBlack = require('../../assets/img/logo/logo.png');

    const togglerWite = require('../../assets/img/design/icon_menu_white.png');
    const togglerBlack = require('../../assets/img/design/icon_menu_gray.png');

    const [menuMobile, setMenuMobile] = useState(false);

    //menu no fixed
    const [isMenufixed, setIsMenufixed] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    //close canva
    const offcanvasButtonRef = useRef(null);

    //scroll menu
    const [isMenuFixedScroll, setMenuFixedScroll] = useState(false);

    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/') {
            setIsMenufixed(false);
            setMenuMobile(false);
        }
        else if (location.pathname === '/photography'||location.pathname === '/videography'||location.pathname==='/design') {
            setIsMenufixed(true);
            setMenuMobile(true);
        } else {
            setIsMenufixed(false);
            setMenuMobile(false);
        }

        const handleResize = () => {
            const isMobileSize = window.matchMedia('(max-width: 991px)').matches;
            setIsMobile(isMobileSize);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        //scroll
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            const threshold = 1;
            setMenuFixedScroll(scrollTop > threshold);
        };
        window.addEventListener("scroll", handleScroll);

        // Limpiar el listener al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location, menuMobile]);

    //close menu
    const [menuActive, setMenuActive] = useState(false);
    const toggleMenu = () => {
        setMenuActive(true);
    };
    const closeMenu = () => {
        setMenuActive(false);
        closeOffcanvas();
    };
    //close canva simulation click
    const closeOffcanvas = () => {
        if (offcanvasButtonRef.current) {
            offcanvasButtonRef.current.click();
        }
    };

    return (
        <>
            <nav className={`navbar bg-body-tertiary  menu  ${isMobile ? '' : isMenufixed ? isMenuFixedScroll?'menu-dark-scroll':'menu-dark ' : 'fixed-top'} ${isMenuFixedScroll ?'scroll  fixed-top':''}`}>
                <div className="container-fluid container-nav">
                    {isMobile
                        ? isMenufixed
                            ? <NavLink to={'/'} className="navbar-brand" href="#"><img src={logoBlack} /></NavLink>
                            : <NavLink to={'/'} className="navbar-brand" href="#"><img src={logoBlack} /></NavLink>

                        : isMenufixed
                            ? <NavLink to={'/'} className="navbar-brand" href="#"><img src={logoBlack} /></NavLink>
                            : <NavLink to={'/'} className="navbar-brand" href="#"><img src={logo} /></NavLink>
                    }

                    <div className='d-none d-lg-block'>
                        <ul className="nav nav-slide">
                            <li className="nav-item">
                                <NavLink to={'/'} className="nav-link active" aria-current="page" >home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'/photography'} className="nav-link" href="#">photography</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'/videography'} className="nav-link" href="#">videography</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'/design'} className="nav-link" href="#">design</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'/'} className="nav-link" href="#">shop</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'/'} className="nav-link" href="#">blog</NavLink>
                            </li>

                        </ul>
                    </div>
                    {menuMobile || isMobile ? (
                        <button onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <img className="toggle" src={togglerBlack} />
                        </button>
                    ) : (
                        <button onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <img className="toggle" src={togglerWite} />
                        </button>
                    )}
                    <div className={`offcanvas offcanvas-end ${menuActive ? 'show' : 'hide'}`} tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <NavLink to={'/'} onClick={closeMenu} className="navbar-brand" href="#"><img src={logoBlack} /></NavLink>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" ref={offcanvasButtonRef} aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item" onClick={closeMenu}>
                                    <NavLink to={'/'} className="nav-link active title" aria-current="page" >home</NavLink>
                                </li>
                                <li className="nav-item list" onClick={closeMenu}>
                                    <NavLink className='title' to={''}> production</NavLink>
                                    <ul>
                                        <li className="nav-item">
                                            <NavLink to={'/photography'} className="nav-link sub-title" >photography</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/videography'} className="nav-link sub-title" >videography</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item list" onClick={closeMenu}>
                                    <NavLink className='title' to={'/design'}> desing</NavLink>
                                    <ul>
                                        <li className="nav-item">
                                            <NavLink to={'/design'} className="nav-link sub-title" >digital desing</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={''} className="nav-link sub-title" >branding</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={''} className="nav-link sub-title" >marketing strategy</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item list" onClick={closeMenu}>
                                    <NavLink className='title' to={''}> about us</NavLink>
                                    <ul>
                                        <li className="nav-item">
                                            <NavLink to={''} className="nav-link sub-title" >contact us</NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/photography" element={<Photography />} />
                <Route path="/videography" element={<Videography />} />
                <Route path="/design" element={<Design />} />
            </Routes>

        </>
    )
}
export { Menu }