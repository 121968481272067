import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class VideographyService {

    getVideos() {
        return http.get(`/videography/`);
    }

}
export default new VideographyService();