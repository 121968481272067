import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';
//services
import CategoryService from '../../services/category'

function Photography() {
    //images
    const slide1 = require('../../assets/img/potograpy/img01.png');
    const slide2 = require('../../assets/img/potograpy/img02.png');
    const slide3 = require('../../assets/img/potograpy/img03.png');

    //content
    const ph_content1 = require('../../assets/img/potograpy/photo01.png');
    const ph_content2 = require('../../assets/img/potograpy/photo02.png');
    const ph_content3 = require('../../assets/img/potograpy/photo03.png');

    //server get
    //categories
    const [categoryGet, setCategoryGet] = useState('');
    //galleries
    const [galleryGet, setGalleryGet] = useState('');
    //images
    const [imagesGet, setIagesGet] = useState('');

    const [isMobile, setIsMobile] = useState(false);

    //category select name
    const [catSelect, setCatSelect] = useState('');
    //gallery selct 
    const [galelrySelect, setGallerySelect] = useState('');
    const [allGSelect, setAllGSelect] = useState('');


    //loading spiner
    const [isLoadingImages, setIsLoadingImages] = useState(true); // State to track loading images

    const [heightVerticalVideography, setHeightVerticalVideography] = useState('');
    const divRef = useRef(null);


    // Función para obtener la altura del div y hacer algo con ella
    const measureHeight = () => {
        if (divRef.current) {
            const height = divRef.current.clientHeight;
            setHeightVerticalVideography(height);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        onLoadCategory();
        const handleResize = () => {
            const isMobileSize = window.matchMedia('(max-width: 991px)').matches;
            setIsMobile(isMobileSize);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setIagesGet, setGalleryGet, catSelect]);

    //get first load places
    const onLoadCategory = async () => {
        try {
            const getAllCategory = await CategoryService.getCategories();

            if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 201) {

                setCategoryGet(getAllCategory.data.data.categories);
                // setAllGallerySelect(getAllCategory.data.data.galleries);
                setIagesGet(getAllCategory.data.data.images);
                setIsLoadingImages(false);
                setAllGSelect(getAllCategory.data.data.galleries);
            } else if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 404) {
            } else if (getAllCategory.status === 200 && getAllCategory.data.output.statusCode === 400) {
            } else {
            }
        } catch (error) {
            //console.log(error.response.status)
        }
    }

    //get galleries
    const onLoadSelectCategory = async (catId, catName) => {
        setCatSelect(catName);
        try {
            const getAllCategory = await CategoryService.getGalleriesById(catId);

            if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 201) {
                // console.log(getAllCategory.data.data.images)
                setGalleryGet(getAllCategory.data.data.galleries);
                setIagesGet(getAllCategory.data.data.images);

            } else if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 404) {
            } else if (getAllCategory.status === 200 && getAllCategory.data.output.statusCode === 400) {
            } else {
            }
        } catch (error) {
            //console.log(error.response.status)
        }
    }
    //get images
    const onLoadSelectGallery = async (galId, galName) => {
        setGallerySelect(galName);
        try {
            const getAllCategory = await CategoryService.getimagesById(galId);
            if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 201) {
                setIagesGet(getAllCategory.data.images);
            } else if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 404) {
            } else if (getAllCategory.status === 200 && getAllCategory.data.output.statusCode === 400) {
            } else {
            }
        } catch (error) {
            //console.log(error.response.status)
        }
    }
    const [activeIndex, setActiveIndex] = useState(0);

    const handleIndicatorClick = (index) => {
        setActiveIndex(index);
    };
    function calculateImageStyle(imgUrl) {
        const img = new Image();
        img.src = imgUrl;

        const aspectRatio = img.width / img.height;
        const imageStyle = {};

        if (aspectRatio < 1) {
            imageStyle.maxHeight = "100%";
            imageStyle.maxWidth = "none";
            imageStyle.display = "flex";
            imageStyle.alignItems = "center";
            imageStyle.justifyContent = "center";
            imageStyle.position = "relative";

            const blurBackground = {
                backgroundImage: `url(${imgUrl})`,
                filter: "blur(30px)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: -1
            };
            imageStyle["--blur-background"] = blurBackground;
        }
        return imageStyle;
    }
    return (
        <>
            <div className='banner-photography'>
                <div className='row'>
                    <div className="col-sm-12 col-md-3 col-lg-3 overflow-auto vertical-gallery order-2 order-sm-2 order-md-2 order-md-1"
                        style={{ height: `${heightVerticalVideography}px` }}>
                        {Array.isArray(imagesGet) && imagesGet.length > 0 ? (
                            imagesGet.map((item, i) => (
                                <img
                                    key={i}
                                    type="button"
                                    data-bs-target="#carouselPHMaju"
                                    data-bs-slide-to={i}
                                    className="img-fluid"
                                    src={item.imgUrl}
                                    onClick={() => handleIndicatorClick(i)}
                                />
                            ))
                        ) : (
                            <div className='center-spinner'>
                                <div className='loader'></div>
                            </div>
                        )}
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-9 order-1 order-sm-1 order-md-1 order-lg-2'>
                        <div className="ul-carousel-container">
                            <div className='ul-container'>
                                <ul className="nav nav-pills nav-fill">
                                    <li className="nav-item dropdown  with-margin">
                                        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                                            {catSelect
                                                ? catSelect
                                                : <>all categories</>
                                            }
                                        </a>
                                        <ul className="dropdown-menu">
                                            {Array.isArray(categoryGet) ? (
                                                categoryGet.map((item, i) => (
                                                    <li key={i}><a className="dropdown-item" onClick={() => onLoadSelectCategory(item.catId, item.catName)} >{item.catName}</a></li>
                                                ))
                                            ) : (
                                                <li key="placeholder" className='placeholder-glow'>
                                                    <span className="placeholder w-25"></span>   <span className="placeholder w-50"></span>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown  with-margin">
                                        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                                            {galelrySelect
                                                ? galelrySelect
                                                : <>galleries</>
                                            }
                                        </a>
                                        <ul className="dropdown-menu">
                                            {Array.isArray(galleryGet) ? (
                                                galleryGet.map((item, i) => (
                                                    <li key={i}><a className="dropdown-item" onClick={() => onLoadSelectGallery(item.galId, item.galName)}>{item.galName}</a></li>
                                                ))
                                            ) : (
                                                Array.isArray(allGSelect) ? (
                                                    allGSelect.map((item, i) => (
                                                        <li key={i}><a className="dropdown-item" onClick={() => onLoadSelectGallery(item.galId, item.galName)}>{item.galName}</a></li>
                                                    ))
                                                ) : (


                                                    <li><span className="placeholder w-25"></span><span className="placeholder w-50"></span></li>
                                                )

                                                /*  <li><span className="placeholder w-25"></span><span className="placeholder w-50"></span></li> */
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="carousel-container">
                                <div
                                    id="carouselPHMaju"
                                    className="carousel slide home-slide"
                                    data-bs-ride="carousel"
                                >
                                    <div className="carousel-indicators">
                                        {Array.isArray(imagesGet) &&
                                            imagesGet.map((item, i) => (
                                                <button
                                                    type="button"
                                                    key={i}
                                                    onClick={() => handleIndicatorClick(i)}
                                                    data-bs-target="#carouselPHMaju"
                                                    data-bs-slide-to={i}
                                                    className={i === activeIndex ? "active" : ""}
                                                    aria-current={i === activeIndex ? "true" : "false"}
                                                    aria-label={`Slide ${i + 1}`}
                                                ></button>
                                            ))}
                                    </div>
                                    <div className="carousel-inner">
                                        {isLoadingImages ? (
                                            <div className="text-center">
                                                <div style={{ marginTop: '10%' }} >
                                                    <div className='center-spinner'>
                                                        <div className='loader'></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            Array.isArray(imagesGet) && imagesGet.length > 0 ? (
                                                imagesGet.map((item, i) => (
                                                    <div
                                                        key={i}
                                                        className={`carousel-item ${i === activeIndex ? "active" : ""}`}
                                                        data-bs-interval="3000"
                                                    >
                                                        <div
                                                            className={`d-block ${item.isVertical ? "vertical-image" : "horizontal-image"}`}
                                                            alt="..."
                                                            style={calculateImageStyle(item.imgUrl)}
                                                        >
                                                            <div className="background-blur" style={calculateImageStyle(item.imgUrl)["--blur-background"]}></div>
                                                            <img src={item.imgUrl} alt="..." />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className='center-spinner'>
                                                    <div className='loader'></div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <button
                                        className="carousel-control-prev"
                                        type="button"
                                        data-bs-target="#carouselPHMaju"
                                        data-bs-slide="prev"
                                        onClick={() => setActiveIndex((prevIndex) => (prevIndex - 1 + imagesGet.length) % imagesGet.length)}
                                    >
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button
                                        className="carousel-control-next"
                                        type="button"
                                        data-bs-target="#carouselPHMaju"
                                        data-bs-slide="next"
                                        onClick={() => setActiveIndex((prevIndex) => (prevIndex + 1) % imagesGet.length)}
                                    >
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-photography accordion" id="accordionExample">
                <div className="card">
                    <div className="card-header view-more" id="headingOne">
                        <div className="mb-0 more-container">
                            <button className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                View services
                                <br />
                                <i className="fa-solid fa-chevron-down"></i>
                            </button>
                        </div>
                    </div>

                    <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="card-body">
                            <div className="bg-pattern-1 content-photography">
                                <div className="container-md">
                                    <div className="elemements-ph">
                                        <div className='elemements-ph '>
                                            <div className='row '>
                                                <div className='col-md-12 col-lg-6 img-r order-1 order-lg-1'>
                                                    <img src={ph_content1} className='img-fluid' />
                                                </div>
                                                <div className='col-md-12 col-lg-6 content-r order-2 order-lg-2'>
                                                    <h4>photorapher</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                                </div>
                                            </ div>
                                            <div className='row'>
                                                <div className='col-md-12 col-lg-6 order-1 order-lg-2'>
                                                    <img src={ph_content2} className='img-fluid' />
                                                </div>
                                                <div className='col-md-12 col-lg-6 content-l order-2 order-lg-1'>
                                                    <h4>creative director</h4>
                                                    <p className='justify-content-end'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-md-12 col-lg-6 order-1 img-r order-lg-1'>
                                                    <img src={ph_content3} className='img-fluid' />
                                                </div>
                                                <div className='col-md-12 col-lg-6 content-r order-2 order-lg-2'>
                                                    <h4>styling</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                                </div>
                                            </ div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export { Photography }