import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

//pages layout
import { Menu } from './pages/layouts/menu';
import { Footer } from './pages/layouts/footer';


function App() {
  return (
    <BrowserRouter>
      {/* <Authprovider> */}
      {/* </Authprovider> */}
      <Menu />
      <Routes>
        {/* <Route path='/' element={<Menu />}></Route> */}

      </Routes>
      <Footer />

    </BrowserRouter>
  );
}

export default App;
